import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TripInfoComponent } from '../components/trips/trip-detail/trip-info/trip-info.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TripRoutesComponent } from '../components/trips/trip-detail/trip-routes/trip-routes.component';
//import { MapRealTimeModule } from './map-real-time.module';
import { TripDetailsComponent } from '../components/trips/trip-detail/trip-details/trip-details.component';
import { TripAlertsComponent } from '../components/trips/trip-detail/trip-alerts/trip-alerts.component';
import { TripShipmentsComponent } from '../components/trips/trip-detail/trip-shipments/trip-shipments.component';
import { environment } from 'src/environments/environment';
import { CommonsLibModule } from 'ng-alto-commons-lib';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { TripTemperatureComponent } from '../components/trips/trip-detail/trip-temperature/trip-temperature.component';
import {TripShipmentPodComponent} from "../components/trips/trip-detail/trip-details/trip-shipment-pod/trip-shipment-pod.component";

@NgModule({
  declarations: [
    TripInfoComponent,
    TripRoutesComponent,
    TripDetailsComponent,
    TripAlertsComponent,
    TripShipmentsComponent,
    TripTemperatureComponent,
    TripShipmentPodComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    CommonsLibModule,
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [TripInfoComponent]
})
export class TripInfoModule { }
