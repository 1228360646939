import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule, registerLocaleData, DatePipe } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorModalComponent } from './components/login/modals/error-modal/error-modal.component';
import { TripInfoModule } from './util/trip-detail.module';
import { AuthInterceptor } from './interceptors/auth-interceptor.interceptor';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AgmCoreModule } from '@agm/core';
import { AccountSelectorComponent } from './components/account-selector/account-selector.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

registerLocaleData(en);


@NgModule({
  declarations: [
    AppComponent,
    ErrorModalComponent,
    PrivacyPolicyComponent,
    AccountSelectorComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TripInfoModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
        },
        deps: [ HttpClient ]
      }
    }),
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_API_KEY,
      apiVersion: "3.41",
      libraries: ["places","drawing","visualization"]
    })
  ],
  providers: [ 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }, 
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: 'env', // you can also use InjectionToken
      useValue: environment
    },
    DatePipe,
    NgbActiveModal
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
