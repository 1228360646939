import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare const localStorage:any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  public login(credentials: any){
    return this.http.post(environment.urlAuthPython +  "/new_login", credentials) 
  }

  public loginDummy(credentials:any){
    if (credentials['email']=='walmart-mx@walmart.com'){
      return dummy_walmart_mx
    }
    return false  
  }


  public logout(){
    return this.http.get(environment.urlAuthPython +  "/logout") 
  }

  public updateTermsConditions(person_id:any){
    return this.http.patch(environment.urlAuthPython +  "/update_privacy_policy", {   
      person_id:  person_id, 
      accept: true,     
      version:  "1",   
      creation_date: "2023-09-06T00:00:00Z"        
      }) 
  
  }

  public verificatePath(path:string): boolean {
    let user:any = localStorage.getItem("User")
    let menus = JSON.parse(user)['menu']
    for (let i = 0; i < menus.length; i++) {
      if( path.includes(menus[i].router_link) ){
        console.log(menus[i].router_link)
        return true;
      }
      else{
        const childs = menus[i].childs;
        for (let j = 0; j < childs.length; j++) {
          if( path.includes(childs[j].router_link) ){
            console.log(childs[j].router_link)
            return true;
          }
          
        }
      }
    }
    return false
  }

  public set AuthToken(v: any) {
    localStorage.setItem("auth:token", v);
  }
  public get AuthToken(): any {
    return localStorage.getItem("auth:token");
  }

  /*Metodo para verificar en la base de datos que el token es valido
  public isLoggedIn(){
    let headers = new HttpHeaders();
    headers = headers.set("Authorization",this.AuthToken);
    return this.http.get(environment.urlAuthPython +  + "/verificate",{headers: headers});  
  }*/

  public isAuthorizedPath(path:any){
    let headers = new HttpHeaders();
    headers = headers.set("Authorization",this.AuthToken);
    return this.http.get(environment.urlAuthPython + "/verificate-path",{headers: headers});  
  }



  public set Profile(v: AuthProfile) {
    if (v === undefined) return;
    localStorage.setItem("profile:profile", JSON.stringify(v));
  }

  public get Profile(): AuthProfile {
    var profile:any = localStorage.getItem("profile:profile")
    return JSON.parse(profile);
  }
  

  public verificatePathPermissions(path:string): boolean {
    return this.permissionsIsOk2(path)
  }


  permissionsIsOk2(path:any){
    //let menu = JSON.parse(localStorage.getItem("User"))['modules_permissions']
    var user:any = localStorage.getItem('User')
    let menu = JSON.parse(user)
    let permissions = {}
    if ('accountPermision' in menu && 'permissions' in menu['accountPermision']){
      permissions =  menu['accountPermision']['permissions']
    }
    console.log("-------------------------")
    console.log(path)
    
    
    
    
    let paths:any[] = path.split('/main')
    paths.unshift()
    if(paths.length>0 && paths[0]==""){
      paths.unshift()
    }
    console.log(path)
    path = paths.join("")
    console.log("Path A Acceder")
    console.log(path)
    console.log("Path Final A Acceder")
    path = path.split("?")[0]
    console.log(path)
    console.log("Permisos")
    console.log(permissions)
    console.log("-------------------------")
    for (let code in permissions) {
      
      /*if( permissions[code]['views'].includes(path)){
        return true
      }*/
      let all_permissions:any = permissions
      let views:any[] = all_permissions[code]['views']
 
      for (let index = 0; index < views.length; index++) {
        let path_search = views[index];
        //var re = /:[A-Z][a-z]/gi; 
        var re = /:([A-Z]*[a-z]*[0-9]*[\_]*)*/gi; 
        path_search = path_search.replace(re, "(.*)"); 
        let er = new RegExp(path_search)
        let test = er.test(path)
        console.log(test)
        if(test){
          let argsPath = path.split("/")
          if(argsPath.length>0 && argsPath[argsPath.length-1]==""){
            argsPath.pop() 
          }
          let argsPathSearch = path_search.split("/")
          if(argsPathSearch.length>0 && argsPathSearch[argsPathSearch.length-1]==""){
            argsPathSearch.pop() 
          }
          if(argsPathSearch.length==argsPath.length){
            return true
          }
          
        } 
        else{
          let path2 = path+"/"
          test = er.test(path2)

          if(test){
            let argsPath = path2.split("/")
            if(argsPath.length>0 && argsPath[argsPath.length-1]==""){
              argsPath.pop() 
            }
            let argsPathSearch = path_search.split("/")
            if(argsPathSearch.length>0 && argsPathSearch[argsPathSearch.length-1]==""){
              argsPathSearch.pop() 
            }
            if(argsPathSearch.length==argsPath.length){
              return true
            }
          }
        }
      }
    }
    return false
  }



  
}

export interface AuthResponse{
  auth:{token:string},
  inactivity_tyme: number,
  role: string, //No se utiliza por ahora
  profile: AuthProfile,
  modules: string, //No se utiliza por ahora
  creates: string, //No se utiliza
  button_header: string, //No se utiliza
  accounts: string, //No se utiliza
  places: string, //No se utiliza
  
}

export interface AuthProfile{
  id: number;
  email: string,
  countries: any,
  last_country_used: number,
  first_name: string,
  middle_name: string,
  last_name: string,
  second_last_name: string,
  username: string,
  person_type: {
    id: number,
    name: string
  },
  profile_picture: string,
  country_phone_code_id: number,
  url: string,
  account: {}
}


var dummy_walmart_mx = {
  "username":"walmart",
  "password":"",
  "id":1900814,
  "email":"walmart-mx@walmart.com",
  "first_name":"Walmart",
  "middle_name":"",
  "last_name":"Mexico",
  "second_last_name":"",
  "person_type":{
    "id":2,
    "name":"ALTO Team"
  },
  "profile_picture":"",
  "countries":[
    {
      "id":1,
      "name":"US"
    }],
  "last_country_used":1,
  "country_phone_code_id":1,
  "accounts":"walmart-mx"}


// public logout(): void {

//   let headers = new Headers();
//   headers.set("Authorization", this.AuthToken);
//   if (!isNullOrUndefined(this.AuthToken)) {
//     this.http.get(environment.api.url + "/logout", { headers: headers }).subscribe(data => {
//       this.isLoggedIn = false;
//       localStorage.clear()
//       console.log(JSON.parse(data["_body"]).message);
//     });
//   }
// }