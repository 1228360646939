import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

declare const localStorage:any;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //Rutas del MS_AUTH que no envian token
    if(!request.url.includes("/auth/login") && !request.url.includes("/auth/verificate")  && !request.url.includes("/auth/valid-token") 
      && !request.url.includes("/auth/recover-password") && !request.url.includes("/auth/reset-password") && !request.url.includes("/auth/valid_token_activate") && !request.url.includes("/auth/generate_password") && !request.url.includes("/assets/i18n/es.json")
      && !request.url.includes("/auth/people/confirm") && !request.url.includes("/auth/people/set_password") ){      
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem("auth:token")}`
          //Authorization: localStorage.getItem("auth:token")
        }
      });
    }
    //return next.handle(request);
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        //Redireccionar si el usuario no esta autorizado y no es una ruta del login. 
        if (err.status === 401 && !request.url.includes("/auth/login")
        && !request.url.includes("/auth/new_login")
        && !request.url.includes("/auth/recover-password")  && !request.url.includes("/auth/reset-password") && !request.url.includes("/auth/valid_token_activate") && !request.url.includes("/auth/generate_password")
        && !request.url.includes("/auth/login") && !request.url.includes("/auth/valid-token")
        ) {
          //this.authService.logout();
          localStorage.clear()
          //alert("Lo sentimos debe iniciar sesión nuevamente, ya que su token no es valido.")
          window.location.href = "/login"
        }
        else if (err.status === 403) {
          window.location.href = "/main/forbidden"
        }
        else if (err.status === 405) {
          localStorage.clear()
          alert("Lo sentimos debe iniciar sesión nuevamente, ya que su token no es valido.")
          window.location.href = "/login"
        }
        return throwError( err );
      }));

  }
}