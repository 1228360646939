import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';


const routes: Routes = [

  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('src/app/components/login/login-routing.module').then(m => m.LoginRoutingModule)
  },
  {
    path: 'trip_detail',
    loadChildren: () => import('src/app/components/trips/trips.module').then(m => m.TripsModule)
  },
  { 
    path: 'main', 
    loadChildren: () => import('src/app/components/main/main.module').then(m => m.MainModule)
  },
  {
    path: 'setpassword',
    loadChildren: () => import('src/app/components/set-password/set-password.module').then(m => m.SetPasswordModule),
  },
  {
    path: 'password', 
    loadChildren: () => import('src/app/module_loaders/password-loader/password-loader.module').then(m => m.PasswordLoaderModule)
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: '**', redirectTo: '/main/not-found'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  // constructor(private winRef:WindowRefService,private router: Router){
  //   winRef.nativeWindow.router = this.router;

  //   this.router.events.subscribe( (event) => {

  //     console.log(event);
  //     if (event instanceof NavigationStart) {
  //         // Show loading indicator
  //         console.log('NavigationStart');
  //     }

  //     if (event instanceof NavigationEnd) {
  //         // Hide loading indicator
  //         console.log('NavigationEnd');
  //         const body = winRef.nativeWindow.document.querySelector("body");
  //         const evt = new winRef.nativeWindow.Event('NavigationEnd');
  //         body.dispatchEvent(evt);
  //     }

  //     if (event instanceof NavigationError) {
  //         // Hide loading indicator

  //         // Present error to user
  //         console.log(event.error);
  //     }
  // });
  // }
}
